<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row no-gutters class="py-1">
      <v-col>
        <v-text-field
          v-model="name"
          :rules="[max255CharRule, presenceRule]"
          dense
          filled
          label="Nome"
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import packageTypeForm from '@/services/packageTypes/packageType.form.js'

export default {
  name: "GeneralFormTab",
  components: {},
  data: function() {
    return {
      name: undefined,
      loadingPackageType: false,
      formValid: false,
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    }
  },
  mounted: function() {
    this.handleObjectChanges(packageTypeForm.packageType)

    var self = this
    packageTypeForm.on('update', function(data) {
      self.handleObjectChanges(data.packageType)
    })
  },
  methods: {
    fields() {
      return ['name']
    },
    handleObjectChanges(packageType) {
      const fields = this.fields()
      const newValKeys = Object.keys(packageType)

      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if(newValKeys.includes(field) && packageType[field] != this[field]) {
          this[field] = packageType[field]
        }
      }
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      packageTypeForm.setValid(newVal)
    },
    name(newVal) {
      packageTypeForm.updateField('name', newVal)
    }
  }
};
</script>